import { CreditCard, TradingPlatformName } from "./types"

export enum RequestState { None, InProgress, Success, Error }
export enum ScreenSize { Desktop = 'Desktop', Tablet = 'Tablet', Mobile = 'Mobile' }
export enum TradeAccountType { Demo = 'DEMO', Live = 'LIVE' }
export type Language =
    'en' |
    // workarounf for Chinese Simplified
    'zh' | // Chinese Simplified
    'zh-Hans' | // Chinese Simplified
    'zh-Hant' |// Chinese Traditional
    'de' |//German
    'ja' |//Japanese
    'es' |//Spanish
    'el' |//Greek
    'cs' |// Czech
    'vi' |// Vietnamese
    'id' |//Indonesia
    'th' |//Thai
    'ms' |// Malay
    'pt' |// Portguese
    'it' |// Italian
    // 'pt-BR' |// Brazilian Portguese
    'ar' |//Arabic
    'pl'//Polish

export const LanguageNames: Record<Language, string> = {
    en: 'English',
    // workarounf for Chinese Simplified
    'zh': "简体中文",//Chinese Simplified
    'zh-Hans': "简体中文",//Chinese Simplified
    'zh-Hant': "繁體中文",//Chinese Traditional
    de: "Deutsch",//German
    ja: "日本語",//Japanese
    es: "Español",//Spanish
    el: "ελληνικά", //Greek
    cs: "Český",//Czech
    vi: "Tiếng Việt",//Vietnamese
    id: "Indonesia",//Indonesia
    th: "ไทย",////Thai
    ms: "Malay",//Malay
    pt: "Português",// Portguese
    it: "Italiano",// Italiano
    // 'pt-BR': "Português",// Brazilian Portguese
    ar: "العربية",//Arabic
    pl: "Polski"//Polish
}

export enum AccountTradingTypes {
    ForexAndCFDs = 1,
    CashEquitiesASX = 2, // AU
    CashEquitiesJSE = 3, // SA
}

export enum PortalAccountDomain {
    Unknown,
    AU = 'AU',
    UK = 'UK',
    TFSA = 'TFSA',
    TMBM = 'TMBM',
    TMSY = 'TMSY',
    TMCY = 'TMCY',
    TMEU = 'TMEU',
    TMJP = 'TMJP',
    TMNZ = 'TMNZ',
    TMLC = 'TMLC',
}

export enum EmploymentStatus {
    Employed = 'Employed',
    Unemployed = 'Unemployed',
    // legacy 
    SelfEmployed = 'Self employed',
    SelfEmployedJPN = 'SelfEmployed',
    Retired = 'Retired',
    Student = 'Student',
    HousewifeOrHousehusband = 'Housewife/Househusband',
    Others = 'Others',
}

export enum SourceOfFunds {
    Employment = 'employment',
    Inheritance = 'inheritance',
    Investment = 'investment',
    Savings = 'savings',
    CompanyFunds = 'companyFunds',
    Other = 'other'
}

export enum IndustryJPN {
    Accommodation = 'Accommodation/ Restaurants/ Life related services',
    Agriculture = 'Agriculture / Forestry / Fishing',
    Banking = 'Financial Services (Banking and Insurance)',
    Construction = 'Construction',
    Education = 'Education and Learning Support',
    Electricity = 'Electricity, gas, heat supply, and water supply',
    Healthcare = 'Healthcare and welfare',
    Import = 'Import/Trade',
    Information = 'Information and Communication',
    Mining = 'Mining/Quarrying/Gravel extraction',
    Other = 'Other industries',
    RealEstate = 'Real estate/goods leasing',
    Research = 'Research/Professional and Technical Services (including Consulting)',
    Securities = 'Financial Services (Securities and Others)',
    Transportation = 'Transportation/Postal',
    Wholesale = 'Wholesale, Sales and Retail',
}

export enum OccupationJPN {
    CompanyOfficer = 'Company officer',
    GovernmentEmployee = 'Government employee',
    OrganizationEmployee = 'Organization employee',
    PartTime = 'Part-time worker',
    RegularEmployee = 'Company employee (regular employee)',
    TempEmployee = 'Company employee (contract, temporary staff)',
}

export enum SolicitationMethod {
    HP = 'HP',
    SNS = 'SNS',
    WebAds = 'Web ads',
    OutdoorAds = 'Outdoor Ads',
    Newspapers = 'Newspapers',
    MagazinesAndBooks = 'Magazines and Books',
    Referrals = 'Referrals',
    Seminars = 'Seminars',
    commercials = 'Commercials',
    Mailmagazines = 'Mail magazines',
}

export enum SourceOfWealth {
    Investment = 'investment',
    Hedging = 'hedging',
    Speculation = 'speculation',
    Other = 'other'
}

export enum PurposeOfAccount {
    Short = 'short',
    MediumTermGrowth = 'mediumTermGrowth',
    LongTermInvestment = 'longTermInvestment',
    Hedging = 'hedging',
    Investment = 'investment'
}

export enum ExpectedOriginOfFunds {
    Bank = 'bankAccount',
    Card = 'creditCard',
    Wallet = 'e-wallet',
    Combination = 'combination'
}

export enum NatureOfTransaction {
    Investment = 'forexAndCFDs',
    Portfolio = 'portfolio',
    Both = 'both'
}

export const NEVER_EXPIRED_DATE = '3000-01-01'

export const SERVER_TIMEOUT = 15000

export const MAX_FILE_SIZE = 10000000

export enum BREAKPOINTS {
    MOBILE = 768,
    DESKTOP = 1100,
}
export enum Gender {
    male = 'M',
    female = 'F',
}

export enum YesOrNo {
    yes = 1,
    no = 2,
}

export const CROSS_RATE_CURRENCY = 'USD'

export const MASTER_OR_INVESTOR_GROUPS = ['_PMMA', '_PMIV']

export const fakeCreditCard: CreditCard = {
    description: '',
    expMonth: 1,
    expYear: 1,
    id: -1,
    last4Digits: '9999',
    subProcessorId: -1,
    type: 'VISA'
}

export const __STORAGE = {
    fa2: '_f',
    u_pass: '_i18p',
    notifications: 'n__',
    accounts: 'a__',
    accountsLast: 'a__l',
    lastClick: '__c__',
    userLoginInfo: '_ul',
    user: '_u__',
    newAccount: '__a',
    init: 'i___',
    session: '_ss__',
    token: '___t',
    liveCongratsShown: '___cngr',
    appInfo: '___appinf',
    profile: '_p______',
    tAc: '_tAc_____',
    hideFA2warning: '__jkl__',
    totalDepositInfo: '_tdi',
    accessToken: '__at_',
    refreshToken: '__rt_',
    validUntil: "vu",
    tmbmMigrationModalShown: '_tmmm__',
    acTypeShown: '_at___',
    platform: 'platform',
    ukAccount: '__uka__',
    creatingIBApplication: 'ib'
}

export const PlatformMap: Record<number, TradingPlatformName> = {
    1: 'MT4',
    5: 'MT5',
    6: 'PAMM_Trading',
    4: 'ThinkTrader',
    2: 'ThinkInvest',
    3: 'TradeInterceptor'
}

export const chineseRegex = /[^\u4E00-\u9FFF\u3400-\u4DFF\uF900-\uFAFF]+/g
export const kanjiNameRegex = /^[ぁ-ん一-龯]+$/
export const katakanaNameRegex = /^[ァ-ンー]+$/
export const allJapaneseSymbolsRegex = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]+$/

export enum CompanyType {
    private = 'private',
    public = 'public'
}
export enum DownloadLinks {
    TTWindowsDesktopTerminal = "https://static.tradeinterceptor.com/distrib/windows/setup.exe",
    TTMacOSDesktopTerminal = "https://itunes.apple.com/app/trade-interceptor-forex-trading/id768615734?mt=12",
    TTMobileIOS = "http://itunes.apple.com/app/trade-interceptor-forex-mobile/id329476057?mt=8",
    TTMobileAndroid = 'http://market.android.com/details?id=com.riflexo.tradeinterceptormobile',

    MT4WindowsDesktopTerminal = "https://download.mql5.com/cdn/web/tf.global.markets/mt4/thinkmarkets4setup.exe",
    MT4MacOSDesktopTerminal = "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg",
    MT4MobileIOS = "https://apps.apple.com/app/metatrader-4/id496212596",
    // MT4MobileIOS = "https://www.thinkmarkets.com/en/about-us/company-news/september-2022/metatrader-apple-app-store-update/",
    MT4MobileAndroid = "https://download.mql5.com/cdn/mobile/mt4/android?server=ThinkMarkets-Demo,ThinkMarkets-Live,ThinkMarkets-Live%202,ThinkMarkets-Live%203,ThinkMarkets-Live%204",

    MT5WindowsDesktopTerminal = "https://download.mql5.com/cdn/web/tf.global.markets/mt5/thinkmarkets5setup.exe",
    MT5MacOSDesktopTerminal = 'https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg?utm_source=support.metaquotes.net',
    MT5MobileIOS = 'https://apps.apple.com/app/metatrader-5/id413251709',
    // MT5MobileIOS = 'https://www.thinkmarkets.com/en/about-us/company-news/september-2022/metatrader-apple-app-store-update/',
    MT5MobileAndroid = 'https://download.mql5.com/cdn/mobile/mt5/android?server=ThinkMarkets-Demo,ThinkMarkets-Live'
}
export const cryptoProcessorNames = ['MCD', 'BitGo', 'CryptoAPIs']

export enum AccountsSort {
    Default,
    Platform,
    Currency,
    Status
}

export enum WtrPageView {
    WTR = 'WTR',
    SSO = 'sso',
    Status = 'status'
}

export enum IBCookieType {
    retail = '1',
    partner = '2'
}