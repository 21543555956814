export const NON_REFER_FRIEND_COUNTRIES: string[] = [
    "AL", // Albania
    "AO", // Angola
    "AR", // Argentina
    "BD", // Bangladesh
    "KH", // Cambodia
    "CO", // Colombia
    "DO", // Dominican Republic
    "EC", // Ecuador
    "GH", // Ghana
    "HN", // Honduras
    "IN", // India
    "IQ", // Iraq
    "KZ", // Kazakhstan
    "XK", // Kosovo
    "PK", // Pakistan
    "PY", // Paraguay
    "PE", // Peru
    "UY", // Uruguay
    "UZ", // Uzbekistan
    "VE", // Venezuela
    "VN", // Vietnam
    "BO", // Bolivia
    "GT", // Guatemala
    "NG", // Nigeria
    "SR", // Suriname
    "CL", // Chile
    "PA", // Panama
    "SV", // El Salvador
    "AE"  // United Arab Emirates
];
