import { CSSProperties } from "react"
import { StylesConfig } from "react-select"
import { SelectComponentsProps } from "react-select/src/Select"
export interface SelectStylesProps {
  height?: number
  value?: any
  validation?: boolean | string
  error?: boolean
  shouldBeFocused?: boolean
  rtl?: boolean,
  readOnly?: boolean,
}

// for use in usual select component
export const getSelectStyles = ({ height = 48, value, validation, error, rtl, readOnly }: SelectStylesProps): StylesConfig => {
  return {
    container: (provided: CSSProperties) => {
      const result = {
        ...provided,
        flex: 1
      }

      return result
    },

    control: (provided: CSSProperties, state: SelectComponentsProps) => {
      const result: CSSProperties & { [index: string]: any } = {
        ...provided,
        minHeight: height,
        // height: height,
        border: '1px solid var(--color-4)',
        borderColor: 'var(--input-border-color) !important',
        borderRadius: 4,
        boxShadow: 'none',
        fontSize: '16px',
        cursor: 'pointer',
        backgroundColor: 'var(--background)',
        color: 'var(--color-4)',
        ':hover': {
          borderColor: 'var(--input-border-color-focused)',
        },
      }

      if (state.isDisabled) {
        result.backgroundColor = 'var(--color-6);'
        result.color = 'var(--color-3)'
      }

      if (state.menuIsOpen) {
        result.color = 'var(--text)'
        result.borderBottomLeftRadius = 0
        result.borderBottomRightRadius = 0
        result.borderColor = 'var(--input-border-color-focused) !important'
        result.borderBottom = 'none'
        result.cursor = 'unset'
        result.backgroundColor = 'var(--background)'
      } else {
        if (state.isDisabled)
          result.borderColor = 'var(--input-border-color) !important'
        else if (value && (!error || validation === true)) {
          result.borderColor = 'var(--input-border-color-focused) !important'
          result.color = 'var(--text)'
        }
        else if (error || validation)
          result.borderColor = 'var(--error) !important'
        else if (state.isFocused) {
          result.borderColor = 'var(--input-border-color-focused) !important'
        }
      }

      if (readOnly) {
        result.backgroundColor = 'inherit'
        if (error) {
          result.borderColor = 'var(--error) !important'
        } else {
          result.borderColor = 'var(--input-border-color) !important'
        }
      }


      return result
    },

    menu: (provided: CSSProperties, state: SelectComponentsProps) => {
      const result: CSSProperties = {
        ...provided,
        boxSizing: 'border-box' as 'border-box',
        margin: 0,
        borderRadius: 4,
        border: '1px solid var(--input-border-color-focused)',
        backgroundColor: 'var(--background)',
        boxShadow: 'none',
        overflow: 'hidden',
        zIndex: 2
      }

      if (state.placement === 'bottom') {
        result.borderTopLeftRadius = 0
        result.borderTopRightRadius = 0
        result.borderTop = 'none'
      } else {
        result.borderBottomLeftRadius = 0
        result.borderBottomRightRadius = 0
        result.borderBottom = 'none'
      }

      return result
    },

    menuList: (provided: CSSProperties, state: SelectComponentsProps) => {
      const result = {
        ...provided,
        borderRadius: 4,
        paddingTop: 0,
        paddingBottom: 0,
        maxHeight: '400px',
        overflowY: 'hidden' as 'hidden',
        boxSizing: 'border-box' as 'border-box'
      }

      if (state.placement === 'bottom') {
        result.borderBottomLeftRadius = 0
        result.borderBottomRightRadius = 0
      } else {
        result.borderTopLeftRadius = 0
        result.borderTopRightRadius = 0
      }

      return result
    },

    dropdownIndicator: (provided: CSSProperties, state: SelectComponentsProps) => {
      const result = {
        ...provided,
        color: state.isFocused ? 'var(--text) !important' : 'var(--color-4) !important'
      }

      if (state.isDisabled) {
        result.display = 'none'
        result.color = 'var(--text) !important'
      }

      if (state.selectProps.menuIsOpen) {
        result.transform = 'rotate(180deg)'
      }

      if (value && (!error || validation === true)) {
        result.color = 'var(--text) !important'
      }

      if (readOnly) {
        result.display = 'none'
      }

      return result
    },


    indicatorSeparator: (raw: CSSProperties) => {
      return { display: 'none' }
    },

    input: (raw: CSSProperties) => {
      return {
        // height: '34px',
        padding: 0,
        margin: 0,
        boxShadow: 'none !important',
        fontSize: '16px',
        color: 'var(--text)',
        caretColor: 'var(--highlight)'

      }
    },

    placeholder: (_: CSSProperties, state: SelectComponentsProps) => {
      return {
        alignSelf: 'center',
        color: state.isFocused ? 'var(--text)' : 'var(--color-4)',
      }
    },

    valueContainer: (provided: CSSProperties) => {
      return {
        ...provided,
        padding: '0',
        paddingInlineStart: '16px',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: '6px'
      }
    },

    singleValue: (provided: CSSProperties) => {
      return {
        alignSelf: 'center',
        // margin: 0,
        display: 'flex',
        color: 'var(--text)',
        lineHeight: 1
      }
    },

    option: (provided: CSSProperties, state: SelectComponentsProps) => {
      const result = {
        ...provided,
        display: 'flex',
        alignItems: 'center',
        minHeight: height,
        height: height,
        padding: '0 16px',
        cursor: 'pointer',
        fontSize: '16px',
        color: state.isSelected ? 'var(--selected-color)' : 'var(--text)',
        backgroundColor: state.isSelected ? 'var(--selected-background)' : 'var(--background)',
        ':active': {
          backgroundColor: 'var(--accent)',
        },
        ':hover': {
          backgroundColor: 'var(--selection-hover)',
          color: 'var(--selection-hover-color)',
          fontWeight: 400
        },
      }
      if (state.data === state.selectProps.value) {
        result.backgroundColor = 'var(--selected-background)'
        result.color = 'var(--selected-color)'
        result.fontWeight = 600
      }

      return result
    }
  }
}

// for use when Select is part of input (amount input with currencies list and phone input)
export const getExtendedSelectStyles = (props: SelectStylesProps): StylesConfig => {
  const { rtl, shouldBeFocused } = props
  const rawSelectStyles = getSelectStyles(props)

  return {
    ...rawSelectStyles,
    container: (provided: CSSProperties, state: SelectComponentsProps) => {
      return {
        ...rawSelectStyles.container!(provided, state),
        flex: '0 0 103px',
      }
    },
    control: (provided: CSSProperties, state: SelectComponentsProps) => {
      const result: any = {
        ...rawSelectStyles.control!(provided, state),
        // color: '#2d2e2c',
      }

      if (!rtl) {
        result.borderTopRightRadius = 0
        result.borderBottomRightRadius = 0
      } else {
        result.borderTopLeftRadius = 0
        result.borderBottomLeftRadius = 0
      }

      if (shouldBeFocused || state.menuIsOpen) {
        result.borderColor = 'var(--input-border-color-focused) !important'
      }

      if (state.menuIsOpen) {
        if (!rtl)
          result.borderBottomLeftRadius = 0
        else
          result.borderBottomRightRadius = 0
      }
      return result
    },
  }
}