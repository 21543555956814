import { TFunction } from 'i18next'
import { useCallback } from 'react'
import { useProcessorImages } from '../../../../resources/images/processors'
import { AccountProcessor, SubProcessor, Limits, Country } from '../../../../utils/types'
import Utils from '../../../../utils/utils'
import { PortalAccountDomain } from '../../../../utils/enums'
import { useUserApplications } from '../../../../utils/hooks'
import { TOTAL_DEPOSITS_LIMIT_AMOUNT, TOTAL_DEPOSITS_LIMIT_CURRENCY } from './useTotalDepositsLimit'

export const unionPayNames = ['FastPay p2p', 'Zotapay-CUP p2p', 'AloPay', 'RPN p2p', 'Nova2pay', 'Nova2Pay p2p', 'Transact365 p2c']
export const processorsWithNotice = [...unionPayNames, 'MPSA', 'OTC365']

export const useIconPathForProcessor = () => {
  const {
    creditCard,
    xpayqr,
    latam,
    ozow,
    bankWire,
    neteller,
    unionPay,
    skrill,
    internationalBankWire,
    localBankTransfer,
    webmoney,
    paymentasia,
    paypal,
    bitpay,
    bpay,
    cardpay,
    poli,
    fasapay,
    astropay,
    sofort,
    praxis,
    crypto,
    googlePay,
    applePay,
  } = useProcessorImages()
  return useCallback((processor: SubProcessor) => {
    if (processor.bank || processor.id === ibwProcessor.id) {
      return internationalBankWire
    }

    if (processor.name === 'CardPay') {
      return cardpay
    }
    switch (processor.displayName) {
      case 'Trade Settlement':
      case 'Quick Payment':
        return internationalBankWire
      case 'Neteller':
        return neteller
      case 'Poli':
        return poli
      case 'FastPay p2p':
      case 'Transact365 p2c':
      case 'Union Pay':
      case 'MyPay':
        return unionPay
      case 'OzowDirect':
        return ozow
      case 'Skrill':
        return skrill
      case 'Credit Card':
      case 'Credit Card (ECP)':
      case 'CheckoutCom':
        return creditCard
      case 'Google Pay':
        return googlePay
      case 'Apple Pay':
        return applePay
      case 'WebMoney':
        return webmoney
      case 'PaymentAsia':
      case 'Payment Asia Local':
        return paymentasia
      case 'PayPal Old':
      case 'PayPal':
        return paypal
      case 'BitPay':
        return bitpay
      case 'FasaPay':
        return fasapay
      case 'AstroPay Card':
        return astropay
      case 'BPay':
        return bpay
      case 'XPay QR':
        return xpayqr
      case 'CardPay Latam':
        return latam
      case 'Sofortüberweisung':
        return sofort
      case 'Bank Wire':
        return bankWire
      case 'Local Bank Transfer':
      case 'Zotapay mPesa':
        return localBankTransfer
      case 'Praxis':
        return praxis
      case 'BitGo':
      case 'Crypto':
      case 'MCD':
      case 'CryptoAPIs':
        return crypto
      case 'PerfectMoney':
        return internationalBankWire
      default:
        break
    }
  }, [applePay, astropay, bankWire, bitpay, bpay, cardpay, creditCard, crypto, fasapay, googlePay, internationalBankWire, latam, localBankTransfer, neteller, ozow, paymentasia, paypal, poli, praxis, skrill, sofort, unionPay, webmoney, xpayqr])

}

const DEFAULT_SORT_NUMBER = 100

const CHN_SORT: Record<string, number> = {
  "MyPay": 2,
  "OTC365": 1,
  "Zotapay-CUP p2p": 3,
}

export const sortProcessors = (processors: AccountProcessor[], userCountry?: Country) => {
  if (userCountry?.code3 === 'CHN') {
    return [...processors].sort((a, b) =>
      (CHN_SORT[a.name] || DEFAULT_SORT_NUMBER) - (CHN_SORT[b.name] || DEFAULT_SORT_NUMBER))
  }
  return [...processors]
}

export const getHeightOfIframe = (processor?: AccountProcessor) => {
  switch (processor?.name) {
    case 'Credit Card (SC)':
    case 'Praxis':
      return '900px'
    case 'Skrill':
      return '750px'
    default:
      return '450px'
  }
}


export const submitPostForm = (method: string, url: string, params: any, acceptCharset?: string, target?: string) => {
  var form = document.createElement("form")
  form.action = url
  form.method = method
  form.target = target || "_self"
  form.acceptCharset = acceptCharset || "UTF-8"
  if (params) {
    for (var key in params) {
      var input = document.createElement("textarea")
      input.name = key
      input.value = typeof params[key] === "object"
        ? JSON.stringify(params[key])
        : params[key]
      form.appendChild(input)
    }
  }
  form.style.display = 'none'
  document.body.appendChild(form)
  form.submit()
  document.body.removeChild(form)
}

export const PROCESSORS_LIMITS: { [name: string]: Limits } = {
  'BitPay': {
    initialMinDeposit: 100,
    minDeposit: 100,
    maxDeposit: 0,
    currency: 'USD',
    accountGroup: '????',
  }
}

export const getMinLimit = (
  limit?: Limits,
  accountCurrency?: string,
  formatter?: (value: number, code?: string) => JSX.Element,
  t?: TFunction,
) => {
  const currency = limit?.currency || accountCurrency
  const value = formatter && limit?.minDeposit
    ? formatter(limit?.minDeposit, currency)
    : limit?.minDeposit || undefined
  return value ? value : t && t('common:noLimit')
}

export const useMaxLimit = () => {
  const { simplifiedAppNotApprovedYet } = useUserApplications()

  return useCallback((
    limit?: Limits,
    accountCurrency?: string,
    formatter?: (value: number, code?: string) => JSX.Element,
    t?: TFunction,
  ) => {
    const currency = simplifiedAppNotApprovedYet ? TOTAL_DEPOSITS_LIMIT_CURRENCY : limit?.currency || accountCurrency
    const limitValue = simplifiedAppNotApprovedYet ? TOTAL_DEPOSITS_LIMIT_AMOUNT : limit?.maxDeposit
    const value = formatter && limitValue
      ? formatter(limitValue, currency)
      : limitValue || undefined
    return value ? value : t && t('common:noLimit')
  }, [simplifiedAppNotApprovedYet])
}

export const ibwProcessor: AccountProcessor = {
  id: -111,
  autoWithdrawalAllowed: false,
  bank: false,
  behavingLikeBankWire: false,
  conversionFee: 0,
  creditCard: false,
  deposit24Limit: 0,
  displayName: 'International Bank Transfer',
  isClientDepositAllowed: false,
  isClientWithdrawalAllowed: false,
  name: 'International Bank Transfer',
  processorFee: 0,
  refundAllowed: false,
  type: "Bank",
  used: true,
}

export const cryptoProcessor: AccountProcessor = {
  id: -222,
  autoWithdrawalAllowed: false,
  bank: false,
  behavingLikeBankWire: false,
  conversionFee: 0,
  creditCard: false,
  deposit24Limit: 0,
  displayName: 'Crypto',
  isClientDepositAllowed: true,
  isClientWithdrawalAllowed: true,
  name: 'Crypto',
  processorFee: 0,
  refundAllowed: false,
  type: "e-Wallet",
  used: true,
}

export const googlePayProcessor: AccountProcessor = {
  id: -444,
  autoWithdrawalAllowed: true,
  bank: false,
  behavingLikeBankWire: false,
  conversionFee: 0,
  creditCard: false,
  deposit24Limit: 1000,
  displayName: "Google Pay",
  isClientDepositAllowed: true,
  isClientWithdrawalAllowed: true,
  limit: undefined,
  name: "Google Pay",
  processorFee: 1,
  refundAllowed: false,
  type: "CC",
  used: true,
}

export const applePayProcessor: AccountProcessor = {
  id: -555,
  autoWithdrawalAllowed: true,
  bank: false,
  behavingLikeBankWire: false,
  conversionFee: 0,
  creditCard: false,
  deposit24Limit: 1000,
  displayName: "Apple Pay",
  isClientDepositAllowed: true,
  isClientWithdrawalAllowed: true,
  limit: undefined,
  name: "Apple Pay",
  processorFee: 1,
  refundAllowed: false,
  type: "CC",
  used: true,
}

export const CHECKOUT_COM_PUBLIC_KEY_TEST = 'pk_sbox_dl635mbmf2ee4u66d2fuyk5koei'
export const CHECKOUT_COM_PUBLIC_KEY_PROD = 'pk_5y5f2iimejazlzqp7lblajsshu6'
export const TMBM_CHECKOUT_COM_PUBLIC_KEY_PROD = 'pk_pxagxd6bjudj6qkl4szq6ivh7qk'

export const getCheckoutComPulicKey = (portalAccountDomain: PortalAccountDomain) => {
  if (Utils.isTestEnv()) return CHECKOUT_COM_PUBLIC_KEY_TEST

  switch (portalAccountDomain) {
    case PortalAccountDomain.TMBM:
    case PortalAccountDomain.TMCY:
    case PortalAccountDomain.TMLC:
      return TMBM_CHECKOUT_COM_PUBLIC_KEY_PROD
    default:
      return CHECKOUT_COM_PUBLIC_KEY_PROD
  }
}

const getMerchantID = (portalAccountDomain: PortalAccountDomain) => {
  switch (portalAccountDomain) {
    case PortalAccountDomain.TMNZ:
      return Utils.isTestEnv() ? 'merchant.apple-pay-test' : 'merchant.apple-pay-prod'
    default:
      return Utils.isTestEnv() ? 'merchant.apple-pay-test' : 'merchant.apple-pay-eu-prod'
  }
}

export const checkIfApplePayAvailable = async (portalAccountDomain: PortalAccountDomain): Promise<boolean> => {
  if (window.ApplePaySession) {
    const merchantID = getMerchantID(portalAccountDomain)
    const canMakePaymentsWithActiveCard = await window.ApplePaySession.canMakePaymentsWithActiveCard(merchantID)
    return canMakePaymentsWithActiveCard
  }
  return false
}
