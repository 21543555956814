import { useTranslation } from "react-i18next"
import { useShouldShowIBportal } from "../../../../ib/utils/hooks"
import { NON_REFER_FRIEND_COUNTRIES } from "../../../../utils/constants/NON_REFER_FRIEND_COUNTRIES"
import {
  useAccounts, useAppState,
  useIsGeneralSidebarEnabled, useOrganization,
  usePlatformAvailability, useShowThinkCapital, useShowTradingView, useUserApplications
} from "../../../../utils/hooks"
import appRoutes from "../../../../utils/routes"
import NewBadge from "../../../Presentational/NewBadge"
import styles from './style.module.scss'
import { DashboardMenuItem, filterMenuItems } from "./utils"

export const useMenuEntries = () => {
  const { t } = useTranslation()
  const { isTMBM, isTMJP, isTMCY, isTMLC, isUK, isAU, isTMNZ } = useOrganization()
  const { accountsLoaded, userReferrerStatus, appStatus, userProfile } = useAppState()
  const { hasLiveAccounts, isDemo, availabeForDeposit, availabeForTransfer,
    enabledLiveAccounts, liveAccounts, demoAccounts } = useAccounts()
  const shouldShowIBportal = useShouldShowIBportal()
  const { hasApprovedCFD, simplifiedAppNotApprovedYet } = useUserApplications()
  const { isThinkCopyPlatformEnabled } = usePlatformAvailability()
  const isGeneralSidebarEnabled = useIsGeneralSidebarEnabled()
  const showTradingView = useShowTradingView()
  const { country } = userProfile || {}

  const fundsEnabled = () => {
    return accountsLoaded && (appStatus === 'APPROVED' || hasLiveAccounts)
  }

  const downloadsEnabled = () => {
    const hasRetailLiveAccounts = liveAccounts.some(x => x.platformAccountType !== 'Commission'
      && x.platformAccountType !== 'PAMM_Investor')
    const hasDemoAccounts = demoAccounts.length > 0
    return showTradingView || (accountsLoaded && (hasRetailLiveAccounts || hasDemoAccounts))
  }

  const showPartner = !(isTMJP || isUK || isAU || isTMNZ || isTMCY)
  const showThinkCapital = useShowThinkCapital()

  const menuItems: DashboardMenuItem[] = [
    {
      name: "accounts",
      customCaption: t('dashboard.sidebar.dashboard'),
      icon: {
        active: '/images/sidebar/accounts.svg',
        inactive: '/images/sidebar/accounts.svg',
        disabled: '/images/sidebar/accounts.svg'
      },
      link: appRoutes.dashboard.accounts.root
    },
    {
      name: "funds",
      customCaption: t('dashboard.sidebar.funds.fundsManagement'),
      icon: {
        active: '/images/sidebar/funds.svg',
        inactive: '/images/sidebar/funds.svg',
        disabled: '/images/sidebar/funds.svg'
      },
      disabled: isDemo,
      children: [
        {
          name: "deposit",
          link: appRoutes.dashboard.funds.deposit,
          disabled: !fundsEnabled() || availabeForDeposit.length === 0,
        },
        {
          name: "withdraw",
          link: appRoutes.dashboard.funds.withdraw,
          keepActiveAppearance: simplifiedAppNotApprovedYet,
          disabled: simplifiedAppNotApprovedYet || !fundsEnabled() || availabeForTransfer.length === 0
        },
        {
          name: "transfer",
          link: appRoutes.dashboard.funds.transfer,
          keepActiveAppearance: simplifiedAppNotApprovedYet,
          disabled: simplifiedAppNotApprovedYet || !fundsEnabled() || availabeForTransfer.length === 0 || enabledLiveAccounts.length < 2
        },
        {
          name: "transaction_history",
          link: appRoutes.dashboard.funds.history,
          disabled: !fundsEnabled(),
        },
        {
          name: "bank_accounts",
          link: appRoutes.dashboard.funds.bankAccounts,
          disabled: !fundsEnabled(),
        }
      ]
    },
    {
      name: "downloads",
      customCaption: showTradingView
        ? <div className={styles.newPlatforms}>{t('dashboard.sidebar.downloads')}<NewBadge /></div>
        : t('dashboard.sidebar.downloads'),
      icon: {
        active: '/images/sidebar/platforms.svg',
        inactive: '/images/sidebar/platforms.svg',
        disabled: '/images/sidebar/platforms.svg'
      },
      disabled: !downloadsEnabled(),
      link: appRoutes.dashboard.downloads
    },
    {
      name: "promotions",
      icon: {
        active: '/images/sidebar/promotions.svg',
        inactive: '/images/sidebar/promotions.svg',
        disabled: '/images/sidebar/promotions.svg'
      },
      shouldDisplay: () => isTMBM || isTMLC,
      children: [
        {
          name: "refer_a_friend",
          customCaption: t('dashboard.sidebar.refer_a_friend'),
          link: appRoutes.dashboard.referAFriend,
          shouldDisplay: () => Boolean(
            (country && !NON_REFER_FRIEND_COUNTRIES.includes(country.code2))
            && (userReferrerStatus?.allowedToBeReferrer || userReferrerStatus?.existingReferrer)
          ),
        },
        {
          name: "vps",
          link: appRoutes.dashboard.tools.vps,
          customCaption: t('dashboard.sidebar.tools.vps'),
        }
      ]
    },
    {
      name: "tools",
      customCaption: t('dashboard.sidebar.tools.tradingServices'),
      icon: {
        active: '/images/sidebar/tools.svg',
        inactive: '/images/sidebar/tools.svg',
        disabled: '/images/sidebar/tools.svg'
      },
      disabled: !isGeneralSidebarEnabled,
      shouldDisplay: () => !isTMJP,
      children: [
        {
          name: "signal_centre",
          link: appRoutes.dashboard.tools.signal_centre.start,
          shouldDisplay: () => accountsLoaded && hasLiveAccounts
        },
        {
          name: "traders_gym",
          link: appRoutes.dashboard.tools.traders_gym,
          shouldDisplay: () => isDemo || hasApprovedCFD
        },
        {
          name: "thinkCopy",
          // icon: {
          //   active: '/images/sidebar/CopyTrading.svg',
          //   inactive: '/images/sidebar/CopyTrading.svg',
          //   disabled: '/images/sidebar/CopyTrading.svg'
          // },
          customCaption: t('dashboard.sidebar.thinkCopy'),
          link: appRoutes.dashboard.thinkCopy,
          shouldDisplay: () => isThinkCopyPlatformEnabled,
        },
        {
          name: "economic_calendal",
          link: appRoutes.dashboard.tools.economic_calendar,
        },
        {
          name: "vps",
          link: appRoutes.dashboard.tools.vps,
          shouldDisplay: () => !(isTMBM || isTMLC)
        },
      ]
    },
    {
      name: "partnerships",
      icon: {
        active: '/images/sidebar/team.svg',
        inactive: '/images/sidebar/team.svg',
        disabled: '/images/sidebar/team.svg'
      },
      shouldDisplay: () => showPartner,
      link: appRoutes.dashboard.partnerships
    },
    {
      name: "thinkcapital",
      icon: {
        active: '/images/sidebar/tcapital.svg',
        inactive: '/images/sidebar/tcapital.svg',
        disabled: '/images/sidebar/tcapital.svg'
      },
      shouldDisplay: () => showThinkCapital,
      link: appRoutes.dashboard.thinkCapital
    },
    {
      name: "support",
      icon: {
        active: '/images/sidebar/support.svg',
        inactive: '/images/sidebar/support.svg',
        disabled: '/images/sidebar/support.svg'
      },
      children: [
        {
          name: "supportRequest",
          link: appRoutes.dashboard.support.clientRequest
        },
        {
          name: "marketing_update",
          link: appRoutes.dashboard.marketing,
          shouldDisplay: () => !(isTMJP || isTMCY)
        },
        {
          name: "faq",
          matchLinks: [appRoutes.dashboard.support.faq]
        },
      ]
    },
    {
      name: "subscription",
      icon: {
        active: '/images/sidebar/subscription.svg',
        inactive: '/images/sidebar/subscription.svg',
        disabled: '/images/sidebar/subscription.svg'
      },
      disabled: !isGeneralSidebarEnabled,
      shouldDisplay: () => isTMJP,
      link: appRoutes.dashboard.subscription
    },
    {
      name: "logout",
      icon: {
        active: '/images/sidebar/logout.svg',
        inactive: '/images/sidebar/logout.svg',
        disabled: '/images/sidebar/logout.svg'
      },
    },
    {
      name: 'separator',
      shouldDisplay: () => shouldShowIBportal
    },
    {
      name: "ib",
      icon: {
        active: '/images/sidebar/ib.svg',
        inactive: '/images/sidebar/ib.svg',
        disabled: '/images/sidebar/ib.svg'
      },
      shouldDisplay: () => shouldShowIBportal
    },
  ]

  return filterMenuItems(menuItems)
}
