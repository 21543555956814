import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { stopPullAppStatus } from '../../../../redux/actions'
import { AppState } from '../../../../redux/appState'
import { setCreatingLive, setLandingPage, setNewPortalAccount, setShowAppSidebar } from '../../../../redux/slices/app'
import { Store } from '../../../../redux/store'
import { useAccounts, useApplicationAccountType, useLandingPage } from '../../../../utils/hooks'
import appRoutes, { dashboardPath, profilePath } from "../../../../utils/routes"
import { useRedirect as individualRedirect } from '../../../../utils/useRedirect'

export const useRedirect = () => {
    const store: AppState = useSelector(
        (state: Store) => state.App)

    const location = useLocation()
    const landingPage = useLandingPage()

    const {
        appStatus,
        appropriatenessLevel,
        userProfile,
        liveAccounts,
        lastDeposit,
        creatingLive,
        uncompletedApplication: uncompletedApp,
    } = store

    const history = useHistory()
    const dispatch = useDispatch()

    const isOutside = location.pathname.indexOf(dashboardPath) === -1 &&
        location.pathname.indexOf(profilePath) === -1

    const isOnProfile = location.pathname.indexOf(profilePath) !== -1


    const { latestLiveAccount } = useAccounts()
    const { redirectToAccounts: redirectToLanding } = individualRedirect()
    const { isAUShares } = useApplicationAccountType()

    const redirectToRegisterFormStep = useCallback(() => {
        const corporate = appRoutes.account.corporate
        if (uncompletedApp) {
            if (uncompletedApp.completed) return
            const { formStep } = uncompletedApp
            if (!formStep || formStep === 0)
                history.push(corporate.corporateInformation)
            else if (formStep === 1)
                history.push(corporate.contacts)
            else if (formStep === 2)
                history.push(corporate.address)
            else if (formStep === 3)
                history.push(corporate.placeOfBusiness)
            else if (formStep === 4)
                history.push(isAUShares
                    ? corporate.representative
                    : corporate.regulation)
            else if (formStep === 5)
                history.push(corporate.representative)
            else if (formStep === 6)
                history.push(corporate.directors)
            else if (formStep === 7)
                history.push(corporate.shareholders)
            else if (formStep === 8)
                history.push(isAUShares
                    ? corporate.chessSponsorship
                    : corporate.selectPlatform)
            else if (formStep === 9)
                history.push(corporate.financialDetails)
            else if (formStep === 10)
                history.push(corporate.experience)
            else if (formStep === 11)
                history.push(appRoutes.account.forexConfirmation)
        }
    }, [history, isAUShares, uncompletedApp])


    const redirectByStatus = useCallback(() => {
        if (isOnProfile) return
        switch (appStatus) {
            case 'PENDING_REVIEW':
                dispatch(setShowAppSidebar(true))
                // dispatch(setNewPortalAccount(true))

                if (creatingLive) {
                    history.push(latestLiveAccount
                        ? `${appRoutes.account.deposit}?account=${latestLiveAccount.account.accountNumber}`
                        : appRoutes.account.congrats)
                } else {
                    if (liveAccounts.length > 0 && !lastDeposit)
                        history.push(appRoutes.account.deposit)
                    else redirectToLanding()
                }
                break
            case 'PENDING':
            case 'PENDING_ID':
            case 'PENDING_ID_ADDRESS':
            case 'PENDING_PROPER_DOCUMENTS':
            case 'PENDING_ADDRESS':
                dispatch(setNewPortalAccount(true))
                if (isOutside) {
                    if (uncompletedApp) history.push(appRoutes.account.verify.id)
                    else redirectToLanding()
                }
                break
            case 'INCOMPLETE':
                dispatch(setCreatingLive(true))
                dispatch(setNewPortalAccount(true))
                redirectToRegisterFormStep()
                break
            case 'PENDING_APPROPRIATENESS_TEST':
            case 'PENDING_RG227':
            case 'DENIED':
            case 'FAILED':
                dispatch(setNewPortalAccount(true))
                dispatch(setShowAppSidebar(true))
                if (appropriatenessLevel === 'REFER') {
                    history.push(appRoutes.account.individualRefer)
                } else {
                    history.push(appRoutes.account.individualFailed)
                }
                break
            case 'APPROVED':
                dispatch(stopPullAppStatus())
                dispatch(setCreatingLive(false))
                redirectToLanding()
                break
            case 'INTERNAL_TESTING':
            case 'PENDING_TRANSLATION':
            case 'PENDING_TRANSFER':
            case 'PENDING_NEW_TnC_ACCEPTANCE':
            case 'DUPLICATE':
            case 'PENDING_ADDRESS_MISMATCH':
                if (isOutside) {
                    history.push(landingPage)
                    dispatch(setLandingPage(undefined))
                }
                break
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appStatus,
        uncompletedApp,
        // appropriatenessLevel,
        // creatingLive,
        // dispatch,
        // history,
        redirectToLanding,
        // lastDeposit,
        // liveAppCompleted,
        // liveAccounts,
        // latestLiveAccount,
        // accountsLoaded,
        uncompletedApp,
        redirectToRegisterFormStep,
        // store,
        userProfile])


    return {
        redirectByStatus,
        redirectToRegisterFormStep,
    }
}