/* eslint-disable no-useless-escape */
import { apm } from '@elastic/apm-rum'
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import { i18n, TFunction } from 'i18next'
import queryString from "query-string"
import moment, { Moment, unitOfTime } from 'moment'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { match } from 'react-router-dom'
import { appConfig, appInit } from '../config'
import { AppError } from './../redux/appState'
import { NON_DESKTOP_TT_COUNTRIES, NON_WTR_COUNTRIES } from './constants/NON_WTR_COUNTRIES'
import * as Enums from "./enums"
import { AccountStat, AccountTradingTypeModel, Answer, APIResponse, Country, IndexedObject, Organization, PostMessageToWTR, Question, QuestionsIDs, SortDirection, TradingPlatformName, UserApplication, UserProfile, UserRecord } from './types'
import { DateInterval } from '../ib/utils/types'

const PASS_PHRASE = '~`:\0/VuPYu!}Sb'
class Utils {

    static isFundingEnabled(stat?: AccountStat) {
        if (!stat) return false
        const groupEnabled = stat && stat.group
            ? Enums.MASTER_OR_INVESTOR_GROUPS.every(g => stat.group.indexOf(g) === -1)
            : true
        return groupEnabled && !stat.archived
    }

    static getAccountsForDeposit(stat: AccountStat[]) {
        return stat.filter(a => this.isFundingEnabled(a) &&
            a.platformAccountType !== 'PAMM_Master_Rebate' &&
            a.account.isDepositAllowed)
    }

    static getAccountsForTransferOrWithdraw(stat: AccountStat[]) {
        return stat.filter(a => this.isFundingEnabled(a) &&
            a.account.isWithdrawAllowed &&
            a.balance > 0.01)
    }

    static isValidNumberKey(key: number, char: string) {
        const digits = /[0-9]/
        return key === 46 || //delete
            key === 8 || //bacspace
            key === 37 || //left
            key === 39 || //right
            key === 35 || //end
            key === 36 || //home
            digits.test(char)
    }

    static isValidEmail(email: string) {
        return /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)
    }

    static isValidInteger(value: string) {
        return /^-?[0-9]+$/.test(value)
    }

    static isValidFloat(value: string) {
        return /\-?\d+\.\d+/.test(value.replace(",", "."))
    }

    static convertFloat(value: string): number {
        return parseFloat(value.replace(",", "."))
    }

    static normalizeFloat(value: string | undefined) {
        return value
            ? value
                .replace(/^\.+/, '')
                .replace(/[^\d\.]/g, '')
                .replace(/^0+(\d+)/, '$1')
                .replace(/^(\d+\.)(.+)$/, (_, p1, p2) => {
                    return p1 + p2.replace(/\./g, '')
                })
            : ''
    }

    static normalizeInteger(value: string | undefined) {
        return value
            ? value
                .replace(/[^\d]/g, '')
                .replace(/^0+(\d+)/, '$1')
            : ''
    }

    static normalizeDigitsOnly(value: string | undefined) {
        // support japanese and usual digits
        return value ? value.replace(/[^\d０-９]/g, '') : ''
    }

    static normalizeDigitsOnlyToFixedLength(decimalPlaces: number) {
        return (value: string | undefined) => {
            return Utils.normalizeDigitsOnly(value).slice(0, decimalPlaces)
        }
    }

    static normalizeIntegerToFixedLength(decimalPlaces: number) {
        return (value: string | undefined) => {
            return Utils.normalizeInteger(value).slice(0, decimalPlaces)
        }
    }

    static normalizeStringToFixedLength(stringLength: number) {
        return (value: string | undefined) => {
            return (value || '').slice(0, stringLength)
        }
    }

    static validateEmail(email: string = '', t: TFunction): string {

        if (!email.length)
            return t('common:email.errors.notDefined')

        if (!this.isValidEmail(email))
            return t(`common:email.errors.notValid`)

        /*         const response: APIResponse<boolean> = await Api.validateEmail({ email })
        
                if (!response.payload[0].result)
                    return t(`${emailString}.errors.checkFailed`)
        */
        return ''
    };

    static validatePassword(password: string | undefined, t: TFunction, validateSecure: boolean = true): string {
        var minMaxLength = /^[\S]{8,30}$/,
            allowed = /^[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/,
            notAllowed = /[^a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/

        if (!password || !password.trim()) return t('common:password.errors.notDefined')
        if (validateSecure) {
            if (notAllowed.test(password)) return t(`common:password.errors.incorrectSymbols`)
            if (!minMaxLength.test(password) || !allowed.test(password))
                return t(`common:password.errors.notSecure`)
        }
        return ''
    };

    static validResponse(response: APIResponse<any>) {
        return response.payload[0].status === 'OK'
    }

    static is18Old(value: Moment) {
        return moment().diff(value, 'years') >= 18
    }

    static get18Suggestion() {
        return moment().subtract(18, 'years').subtract(1, 'day').toDate()
    }

    static get100yoSuggestion() {
        return moment().subtract(100, 'years').subtract(1, 'day').toDate()
    }

    static statusError(response: string | APIResponse<any>): AppError {
        const status = typeof response === 'object'
            ? response.payload[0].status
            : response
        const message = typeof response === 'object'
            ? response.payload[0].result?.message
            : ''
        const error = typeof response === 'object'
            ? `${status} in ${response.payload[0].module}->${response.payload[0].action}, message: ${message}`
            : status
        apm.captureError(error)
        return { type: 'status', status }
    }

    static customError(text: string, caption?: string, description?: string): AppError {
        apm.captureError(text)
        return { type: 'custom', caption, text, description }
    }

    static apiError(response: APIResponse<any>, t?: TFunction): AppError {
        if (response.payload[0].status === 'ALREADY_REGISTERED')
            return this.statusError(response.payload[0].status)
        if (response.payload[0].result && response.payload[0].result.message) {
            const message = response.payload[0].result.message
            return this.customError(t ? t(message) : message)
        }
        else return this.statusError(response.payload[0].status)
    }

    static capitalize(text: string) {
        try {
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
        }
        catch { return text }
    }

    static capitalizeFirstLetterOnly(text: string) {
        try {
            return text.toLowerCase().charAt(0).toUpperCase() + text.slice(1).toLowerCase()
        }
        catch { return text }
    }

    static capitalizeWords(text: string) {
        try {
            return text.split(' ').map(s => this.capitalize(s)).join(' ')
        }
        catch { return text }
    }

    static checkIfObjHasDefinedValues(rawObject?: { [indx: string]: any }) {
        if (rawObject) {
            for (var prop in rawObject) {
                if (rawObject.hasOwnProperty(prop) && rawObject[prop]) {
                    return false
                }
            }
        }
        return true
    }

    static sortStrings(dir: SortDirection, x: string, y: string) {
        return dir === 'ascend' ? y.localeCompare(x) : x.localeCompare(y)
    }

    static sortNumbers(dir: SortDirection, x: number, y: number) {
        return dir === 'ascend' ? x - y : y - x
    }

    static sortDates(dir: SortDirection, x: string, y: string) {
        const xTs = new Date(x).getTime()
        const yTs = new Date(y).getTime()
        return dir === 'ascend' ? xTs - yTs : yTs - xTs
    }

    static updateObject<T extends object>(data: T[], identity: (x: T) => boolean, update: (x: T) => T) {
        return data.map(obj => (identity(obj) ? update(obj) : obj))
    }

    static identity<T extends any>(x: T): T { return x }

    static hasSpecialChars(data: string) {
        return /[$&+,:;=?@#|<>^*()%!`~"/\\{}\[\]\d]/.test(data)
    }

    static checkGoToAppropriatenessTest(userProfile: UserProfile | null) {
        const country = userProfile?.country?.code2.toLowerCase()
        return country === 'au' || country === 'nz'
    }

    static checkIfUserFromUK(userProfile: UserProfile | null) {
        return Boolean(userProfile && userProfile.country.code2.toLowerCase() === 'gb')
    }

    static checkIfUserFromSouthAfrica(userProfile: UserProfile | null) {
        return Boolean(userProfile && userProfile.country.code2.toLowerCase() === 'za')
    }

    static checkIfUserFromVietnam(userProfile: UserProfile | null) {
        return Boolean(userProfile && userProfile.country.code2.toLowerCase() === 'vn')
    }

    static checkIfShowAppropriatenessTest(userProfile: UserProfile | null, questions?: QuestionsIDs[]) {
        const areAnswersIsLessThan1Year = questions && questions.length === 2
            ? questions.every(x => x.answer === 14)
            : false
        return Utils.checkGoToAppropriatenessTest(userProfile) && areAnswersIsLessThan1Year
    }

    static saveEncryptedString<T extends string>(name: string, value: T, storage: Storage = localStorage) {
        storage[name] = AES.encrypt(value, PASS_PHRASE)
    }

    static loadEncryptedString<T extends string>(name: string, storage: Storage = localStorage): T | undefined {
        try {
            return AES.decrypt(storage[name], PASS_PHRASE).toString(Utf8) as T
        }
        catch { return undefined }
    }

    static saveEncryptedObject(name: string, object: Object, storage: Storage = localStorage) {
        storage[name] = AES.encrypt(JSON.stringify(object), PASS_PHRASE)
    }

    static saveUserRecord<T>(name: string, object: UserRecord<T>, storage: Storage = localStorage) {
        if (object.email)
            this.saveEncryptedObject(name, object, storage)
    }

    static loadUserRecord<T>(name: string, email?: string, storage: Storage = localStorage) {
        const record = this.loadEncryptedObject<UserRecord<T>>(name, {}, storage)
        if (record.email === email) return record.value as T
        return null
    }

    static deleteKey(name: string, storage: Storage = localStorage) {
        storage.removeItem(name)
    }

    static loadEncryptedObject<T extends Object>(name: string, defaultValue: object = {}, storage: Storage = localStorage): T {
        try {
            return JSON.parse(AES.decrypt(storage[name], PASS_PHRASE).toString(Utf8)) as T
        }
        catch {
            storage.removeItem(name)
            return defaultValue as T
        }
    }

    static getBaseUrlFromMatch(match: match<any>): string {
        const paramIndex = match.path.indexOf(':')
        if (paramIndex === -1) return match.url
        return match.url.slice(0, paramIndex - 1)
    }

    static isProductionBuild() {
        return process.env.NODE_ENV === 'production'
    }

    static isTestEnv() {
        const build = (appConfig.ENV || "productionld").trim()
        return ['uat', 'dev', 'development', 'staging'].includes(build)
    }

    static isTestBuild() {
        return process.env.NODE_ENV !== 'production'
    }

    static bytesToSize(bytes: number) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return 'n/a'
        const i = Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024))
        if (i === 0) return `${bytes} ${sizes[i]})`
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    }

    static objectValues(data: any) {
        if (typeof data === 'object') return Object.values(data)
        else return data
    }

    static isObject(data: any) {
        return typeof data === 'object' && data !== null
    }

    static leverageString2Number(leverage?: string, defaultValue: number = 100) {
        return leverage
            ? parseInt(leverage.replace('L_', ''))
            : defaultValue
    }

    static checkIfCountryIsChina(country?: Country | undefined | null) {
        return Boolean(country && country.code3 === 'CHN')
    }

    static checkIfCountryIsUAE(country?: Country | undefined | null) {
        return Boolean(country && country.code3 === 'ARE')
    }

    static checkDIFCentity(country?: Country | undefined | null, portalAccountDomain?: Enums.PortalAccountDomain | null) {
        return portalAccountDomain === Enums.PortalAccountDomain.UK
            && Utils.checkIfCountryIsUAE(country)
    }

    static checkIfCountryIsNonCNOrVN(country?: Country | undefined | null) {
        return country && !['CHN', 'TWN', 'HKG', 'VNM'].includes(country.code3)
    }

    static checkIfCountryIsAustralia(country?: Country | null) {
        return Boolean(country && country.code3 === 'AUS')
    }

    static checkIfCountryIsSA(country?: Country | null) {
        return Boolean(country && country.code3 === 'ZAF')
    }

    static checkIfAllowCashEquities(country?: Country | null, userOrg?: Organization) {
        const conditionForAU = Utils.checkIfCountryIsAustralia(country) && (
            userOrg ? userOrg.name === 'AU' : true
        )
        const conditionForSA = Utils.checkIfCountryIsSA(country) && (
            userOrg ? userOrg.name === 'TFSA' : true
        )

        return conditionForAU || conditionForSA
    }

    static getDefaultPlatform(country?: Country | undefined): TradingPlatformName {
        return Utils.checkIfCountryIsChina(country) ? 'MT4' : 'ThinkTrader'
    }

    static decibelInsight(eventName: string, value?: any) {
        if (typeof window.decibelInsight !== 'undefined') {
            window.decibelInsight("sendTrackedEvent", eventName, value)
            console.log('>> sendTrackedEvent', eventName, value)
        }
    }

    static decibelSendCustomDimension(dimensionName: string, value: any) {
        if (typeof window.decibelInsight !== 'undefined') {
            window.decibelInsight("sendCustomDimension", dimensionName, value)
            console.log('>> sendCustomDimension', dimensionName, value)
        }
    }

    static floorToTwoDecimals(value: number) {
        return Math.floor(value * 100) / 100
    }

    static fixToSpecificDecimal(value: number, decimalValue: number,) {
        return value ? value.toFixed(decimalValue) : '0'
    }

    static formatAmount(value: number, currencyCode: string) {
        if (currencyCode === 'BTC') {
            return Utils.fixToSpecificDecimal(value || 0, 8)
        }
        if (currencyCode === 'ETH') {
            return Utils.fixToSpecificDecimal(value || 0, 5)
        }
        return Utils.fixToSpecificDecimal(value || 0, 2)
    }

    static filterOpenApps(applications: UserApplication[] | null) {
        return applications?.filter(x => x.applicationStatus !== 'APPROVED')
    }

    static string2TradingPlatformName(name: string): TradingPlatformName {
        switch (name.toUpperCase()) {
            case 'MT4': return 'MT4'
            case 'MT5': return 'MT5'
            case 'THINKTRADER': return 'ThinkTrader'
            case 'THINKINVEST': return 'ThinkInvest'
            case 'TRADEINTERCEPTOR': return 'TradeInterceptor'
            case 'THINK_COPY': return 'THINK_COPY'
        }
        return 'MT4'
    }

    static isUkLikeOrg(portalAccountDomain: Enums.PortalAccountDomain): boolean {
        return [
            Enums.PortalAccountDomain.UK,
            Enums.PortalAccountDomain.TMCY,
            Enums.PortalAccountDomain.TMEU,
        ].includes(portalAccountDomain)
    }

    static getLanguage(country_code?: string): Enums.Language {
        if (navigator && navigator.language) {
            const lang = navigator.language.toLowerCase()
            const is = (lng: string) => {
                return lang.startsWith(lng)
            }
            switch (true) {
                case is('ar'): return 'ar'
                case lang === 'zh-hans': return 'zh-Hans'
                case lang === 'zh-hant': return 'zh-Hant'
                case is('zh'): return 'zh-Hans'
                case is('en'): return 'en'
                case is('cs'): return 'cs'
                case is('de'): return 'de'
                case is('el'): return 'el'
                case is('es'): return 'es'
                case is('it'): return 'it'
                case is('ms'): return 'ms'
                case is('pl'): return 'pl'
                case is('th'): return 'th'
                case is('vi'): return 'vi'
                case is('pt'): return 'pt'
                case is('id'): return 'id'
                case is('in'): return 'id'
                case is('ja'): return 'ja'
                default: return 'en'
            }
        }
        if (country_code)
            switch (country_code.toUpperCase()) {
                case "CN": return 'zh-Hans'
                case "DE": return 'de'
                case "JP": return 'ja'
                case "ES": return 'es'
                case "GR": return 'el'
                case "CZ": return 'cs'
                case "VN": return 'vi'
                case "ID": return 'id'
                case "TH": return 'th'
                case "MY": return 'ms'
                case "BR": return 'pt'
                case "IT": return 'it'
                case "SA": return 'ar'
                case "PL": return 'pl'
                default: return 'en'
            }
        return 'en'
    }


    static setStorageBoolean(value: boolean, name: string) {
        if (value) localStorage[name] = '1'
        else delete localStorage[name]
    }

    static getStorageBoolean(name: string): boolean {
        return localStorage[name] === '1'
    }

    static removeDuplicates<T extends object>(arr: T[], prop: keyof T): T[] {
        const seen = new Set()
        const filteredArr = arr.filter(item => {
            const duplicate = seen.has(item[prop])
            seen.add(item[prop])
            return !duplicate
        })
        return [...filteredArr]
    }

    static setSaCeBalance = (account: AccountStat | null | undefined): number => {
        if (!account) return -1

        const model = account.account.accountTradingType as AccountTradingTypeModel

        if (model.description === 'CE - JSE') {
            if (account.wdbalance === undefined) {
                return -1
            }

            return account.wdbalance
        }

        return account.balance
    }

    static setBalanceMinLimit = (account: AccountStat | null): number => {
        if (!account) return -1
        const model = account.account.accountTradingType as AccountTradingTypeModel

        if (model.description === 'CE - ASX' || model.description === 'CE - JSE') {
            return 0.01
        }

        return 30
    }

    static nowGreaterThen(name: string, value: number, units: unitOfTime.Diff, defaultValue?: number): boolean {
        const now = moment()
        const last = localStorage[name]
            ? moment(+localStorage[name])
            : defaultValue ? defaultValue : moment().valueOf()
        return now.diff(last, units) > value
    }

    static writeTimestamp(name: string, time?: number) {
        localStorage[name] = time ? time : moment().valueOf()
    }

    static loadScript(src: string, handleOnLoad: () => void, id: string = "", async = false) {
        if (id) {
            const script = document.getElementById(id)
            script && script.remove()
        }
        const tag = document.createElement('script')
        tag.async = async
        if (id) tag.id = id
        tag.src = src
        tag.onload = () => {
            handleOnLoad && handleOnLoad()
        }

        document.body.appendChild(tag)
    }

    static findAnswerID(question: Question | undefined, answer?: string): number {
        if (!question || !answer) return 0
        const id = question.answers.find(a => a.label === answer)
        return id ? id.id : 0
    }

    static findAnswer(questionLabel: string, questions: Question[] = [], answers: QuestionsIDs[] = []): Answer | undefined {
        const question = questions.find(q => q.label === questionLabel)
        if (question) {
            const answerId = answers.find(a => a.question === question?.id)
            return question.answers.find(a => a.id === answerId?.answer)
        }
        return undefined
    }

    static isSEACountry(country: Country): boolean {
        return ["TWN", "MYS", "IDN", "PHL", "BRN"].includes(country.code3)
    }

    static parseDate(date?: Date): {
        day: number
        month: number
        year: number
    } | undefined {
        return date ? {
            day: date.getDate(),
            month: date.getMonth() + 1,
            year: date.getFullYear(),
        } : undefined
    }

    static parseDayMonthYear(d?: number, m?: number, y?: number) {
        return moment(`${d}/${m}/${y}`, 'DD/MM/YYYY')
    }

    static trimValue(value?: string): string {
        return value ? value.trim() : ''
    }

    static getCurrencySign(currency?: string) {
        return currency === 'JPY' ? '円' : currency
    }

    static downloadFile(href: string, name?: string) {
        const link = document.createElement("a")
        link.href = href
        link.target = "_blank"
        if (name) {
            link.download = name
        }
        link.rel = "noopener noreferrer"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    static calculateMarginLevel(account: AccountStat) {
        const { equity = 0, margin = 0 } = account
        const marginLevel = margin > 0 ? (equity / margin) * 100 : 0
        return +marginLevel.toFixed(2)
        // old formula
        // return balance > 1E-6 ? Math.floor((balance - marginFree) / balance * 100) : 0
    }

    static getFullCoinRepresentation(code: string, fullName: string, t: TFunction) {
        if (code === 'USDT' || code === 'USDC') {
            return `${code} (ERC20)`
        }
        if (code === 'TRC20') {
            return `USDT (${code})`
        }
        if (code === 'BUSD') {
            return `${fullName} (ERC20)`
        }
        return `${fullName} (${code})`
    }

    static getShortCoinRepresentation(code?: string) {
        if (code === 'USDT' || code === 'USDC') {
            return `${code} (ERC20)`
        }
        if (code === 'TRC20') {
            return `USDT (${code})`
        }
        return code || ''
    }

    static async postMessageToWTR(message: PostMessageToWTR) {
        try {
            await appInit
            window.parent.postMessage(message, `${appConfig.WTR_URL}`)
        }
        catch { }
    }

    static wtrAppSession = () => sessionStorage.getItem('wtrApp') ? sessionStorage.getItem('wtrApp') === 'true' : false

    static shouldAllowWTR(userProfile: UserProfile | null) {
        return userProfile
            ? !NON_WTR_COUNTRIES.includes(userProfile.country.code3)
            : false
    }

    static shouldAllowTTdesktop(userProfile: UserProfile | null) {
        return userProfile
            ? !NON_DESKTOP_TT_COUNTRIES.includes(userProfile.country.code3)
            : false
    }

    static shouldShowTradingCentralScreen(portalAccountDomain: Enums.PortalAccountDomain) {
        if (portalAccountDomain === Enums.PortalAccountDomain.TMJP) {
            return false
        }
        return true
    }

    static checkForAdditionalConsent(country?: Country | null) {
        return country && (country.code3 === 'FRA' || country.code3 === 'ESP')
    }

    static combineTranslations<T extends Object>(prefix: string, t: TFunction, i18n: i18n): T {
        const en = i18n.getFixedT('en')(prefix, { returnObjects: true }) as IndexedObject
        const lang = t(prefix, { returnObjects: true }) as IndexedObject

        const compare = (e: IndexedObject, l: IndexedObject) => {
            const isObject = (value: any) => {
                return !!(value && typeof value === "object")
            }
            const entries = Object.entries(e)
            for (let i = 0; i < entries.length; i += 1) {
                const [k, v] = entries[i]
                if (isObject(v)) {
                    if (!l[k]) l[k] = v
                    else compare(e[k], l[k])
                }
                else {
                    if (!l[k]) l[k] = v
                }
            }

        }
        compare(en, lang)
        return lang as T

    }

    static replaceLink = (content: string, link: string): string => {
        return content.replace(/\{.*?\}/g, match => {
            const caption = match.replace(/\{|\}/g, '')
            return `<a target="_blank" rel="noopener noreferrer" href=${link}>${caption}</a>`
        })
    }

    static checkIfJapanDemoExpired = (allAccounts: AccountStat[], userProfile: UserProfile | null, userApps: UserApplication[]) => {
        const isTMJP = userProfile?.country.organization.name === Enums.PortalAccountDomain.TMJP

        const hasApplication = userApps.length > 0
        const hasExpiredDemo = allAccounts.filter(a => a.account.type.toUpperCase() === 'DEMO' && a.account.expires?.isExpired).length > 0
        return hasExpiredDemo && !hasApplication && isTMJP
    }

    static maskStartCharacters(str: string | undefined, mask: string = '*', n = 4) {
        return str
            ? mask.repeat(n) + str.substring(n)
            : mask
    }

    static setWithExpiry = (key: string, value: string, ttl: number) => {
        const item = {
            value: value,
            expiry: new Date().getTime() + ttl
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    static getWithExpiry(key: string) {
        const itemString = window.localStorage.getItem(key)
        if (!itemString) return null

        const item = JSON.parse(itemString)
        const isExpired = new Date().getTime() > item.expiry

        if (isExpired) {
            localStorage.removeItem(key)
            return null
        }

        return item.value
    }

    static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    static retrieveAccTradingType(rawArr: Array<{ id: Enums.AccountTradingTypes } | Enums.AccountTradingTypes> | undefined) {
        if (!rawArr?.length) return Enums.AccountTradingTypes.ForexAndCFDs
        if (rawArr[0] instanceof Object) {
            return rawArr[0].id
        } else {
            return rawArr[0]
        }
    }

    static isLeverage30Org(organization?: Organization) {
        return organization
            ? organization.name === "AU" ||
            organization.name === "UK" ||
            organization.name === "TMCY"
            : false
    }

    static isValidDateInterval = (dates: DateInterval): boolean => {
        if (!dates[0] || !dates[1]) return false
        return true
    }

    static removeTrailingSlash(path: string) {
        return path.replace(/\/+$/, '')
    }

    static deepFlattenToObject(obj: IndexedObject, prefix = '') {
        return Object.keys(obj).reduce((acc: IndexedObject, k) => {
            const pre = prefix.length ? prefix + '_' : ''
            if (typeof obj[k] === 'object' && obj[k] !== null) {
                Object.assign(acc, this.deepFlattenToObject(obj[k], pre + k))
            } else {
                acc[pre + k] = obj[k]
            }
            return acc
        }, {})
    }

    static getIBcookie(): { pid: string, type: Enums.IBCookieType | null, cid: string } | null {
        const query = queryString.parse(window.location.search)
        const parsedPID = query.pid as string
        const parsedType = query.type as string
        const parsedCID = query.cid as string

        if (parsedPID && parsedType) {
            return {
                pid: parsedPID,
                type: parsedType as Enums.IBCookieType,
                cid: parsedCID || ''
            } 
        }

        const cookieName = 'ibc'
        const cookies = document.cookie.split('; ')
        const cookie = cookies.find(c => c.startsWith(`${cookieName}=`))

        if (cookie) {
            const cookieValue = cookie.split(`${cookieName}=`)[1]
            const params = new URLSearchParams(cookieValue)
    
            return {
                pid: params.get('pid') || '',
                type: params.get('type') as Enums.IBCookieType || null,
                cid: params.get('cid') || ''
            }
        }

        return null
    }
}

export default Utils
export { PASS_PHRASE }

